<template>
  <v-menu class="filter-menu" left offset-x :close-on-content-click="false" max-height="600">
    <template v-slot:activator="{ on }">
      <v-btn text color="primary" class="unset-capitalize" v-on="on">
        Gerenciar colunas
        <mf-icon fill color="primary" size="20" class="ml-2">settings</mf-icon>
      </v-btn>
    </template>

    <v-card width="300px">
      <v-card-text>
        <h1 class="subtitle-1 grey--text text--darken-2 mb-0">Exibir colunas:</h1>
        <p class="pa-0 grab-to-order">Arraste para ordenar</p>

        <draggable v-model="localHeaders" handle=".grabbable">
          <transition-group>
            <div v-for="(header, indexHeader) in localHeaders" :key="`${header.text}-${indexHeader}`">
              <v-list-item v-if="header.text" class="px-0 mb-1 grabbable">
                <v-list-item-action class="ml-n1 mr-1">
                  <mf-icon color="grey"> drag_indicator </mf-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" class="grey--text text--darken-1 body-2 grabbable" v-on="on">{{ header.text }}</span>
                      </template>
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <mf-toggle v-model="header.isActive" :disabled="header.isRequired" @change="changeHeaderIsActive" />
                </v-list-item-action>
              </v-list-item>
            </div>
          </transition-group>
        </draggable>

        <section class="d-flex flex-row justify-space-between pt-4">
          <v-btn small text color="error" @click="resetSettings"> Limpar </v-btn>

          <v-btn small color="primary" @click="applyAllSettings"> Marcar todos </v-btn>
        </section>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { cloneDeep } from 'lodash'

import draggable from 'vuedraggable'

export default {
  name: 'HeadersDataTableSettings',
  components: {
    draggable
  },
  props: {
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    defaultHeaders: {
      type: Array,
      required: false,
      default: () => []
    },
    headersSettingsName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    currentHeaders: []
  }),
  computed: {
    localHeaders: {
      get() {
        return this.headers
      },
      set(value) {
        this.setSettings(value)
      }
    }
  },
  watch: {
    headersSettingsName() {
      this.localHeaders = this.defaultHeaders
    }
  },
  mounted() {
    const savedHeaders = window.localStorage.getItem('headers-data-table-settings')
    const savedHeadersJSON = JSON.parse(savedHeaders)
    if (savedHeadersJSON && savedHeadersJSON[this.headersSettingsName]) {
      // if user active or deactive modules, headers should restore to the default settings
      const savedHeadersStr = JSON.stringify(savedHeadersJSON[this.headersSettingsName].map(header => header.value).sort())
      const defaultHeadersStr = JSON.stringify(this.defaultHeaders.map(header => header.value).sort())
      if (savedHeadersStr !== defaultHeadersStr) this.resetSettings()
      else this.localHeaders = savedHeadersJSON[this.headersSettingsName]
    } else {
      this.localHeaders = this.defaultHeaders
    }
  },
  methods: {
    setSettings(newHeaders) {
      const settings = window.localStorage.getItem('headers-data-table-settings')
      let newSettings = {}

      if (settings) {
        const settingsJSON = JSON.parse(settings)
        newSettings = { ...settingsJSON, [this.headersSettingsName]: newHeaders }
      } else {
        newSettings = { [this.headersSettingsName]: newHeaders }
      }

      window.localStorage.setItem('headers-data-table-settings', JSON.stringify(newSettings))
      this.$emit('update:headers', newHeaders)
    },
    applyAllSettings() {
      const newHeaders = cloneDeep(this.headers).map(header => {
        header.isActive = true
        return header
      })
      this.setSettings(newHeaders)
    },
    resetSettings() {
      this.setSettings(this.defaultHeaders)
    },
    changeHeaderIsActive() {
      this.setSettings(this.localHeaders)
    }
  }
}
</script>

<style lang="scss" scoped>
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.not-grabbable {
  cursor: not-allowed;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.grab-to-order {
  color: #78838e;
}
</style>
